<template>
  <a-card :title="$t('复核记录详情')">
    <!-- <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
      <a-icon type="printer" />{{ $t("打印") }}</a-button
    > -->
    <a-button
      slot="extra"
      type="primary"
      ghost
      @click="
        () => {
          this.$router.go(-1);
        }
      "
    >
      <a-icon type="left" />{{ $t("返回") }}</a-button
    >
    <section id="printContent">
      <a-spin :spinning="spinning">
        <a-descriptions bordered>
          <a-descriptions-item :label="$t('出库通知单号')">
            {{ detailInfo.delivery_order_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('订单类型')">
            {{ detailInfo.order_type_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('物流单号')">
            {{ detailInfo.logistics_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('货主')">
            {{ detailInfo.client_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('仓库')">
            {{ detailInfo.warehouse_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('仓库编号')">
            {{ detailInfo.warehouse_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('往来单位单号')">
            {{ detailInfo.external_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('收货地址')">
            {{ detailInfo.receiving_address }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建人')">
            {{ detailInfo.creator_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建时间')">
            {{ detailInfo.create_time }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('备注')">
            {{ detailInfo.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="materialItems"
          :loading="materialLoading"
          :pagination="false"
        >
        </a-table>
      </div>
    </section>
  </a-card>
</template>

<script>
import { deliveryRecordDetail, deliveryRecordMaterials } from "@/api/stockOut";

export default {
  name: "DeliveryRecordDetail",
  components: {},
  data() {
    return {
      description: this.$t("详情"),
      spinning: false,
      materialLoading: false,
      visible: false,
      detailInfo: {},
      materialItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: this.$t("货品编号"),
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: this.$t("数量"),
          dataIndex: "total_quantity",
          key: "total_quantity",
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: this.$t("计费量"),
          dataIndex: "charge_value",
          key: "charge_value",
        },
        {
          title: this.$t("计费单位"),
          dataIndex: "charge_unit",
          key: "charge_unit",
        },
        {
          title: this.$t("序列号"),
          dataIndex: "serial_number",
          key: "serial_number",
        },
        {
          title: this.$t("箱号"),
          dataIndex: "packing_number",
        },
      ],
    };
  },
  computed: {
    enableCharge() {
      return this.$store.state.user.enableCharge;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableCharge && item.key === "charge_value") {
          continue;
        }

        if (!this.enableCharge && item.key === "charge_unit") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initData() {
      this.getData(this.$route.query.id);
    },
    getData(id) {
      this.spinning = true;
      this.materialLoading = true;
      deliveryRecordDetail({ id })
        .then((data) => {
          this.detailInfo = data;
        })
        .finally(() => {
          this.spinning = false;
        });
      deliveryRecordMaterials({ id })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
